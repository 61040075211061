import { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandPinterest,
  IconBrandTiktok,
  IconBrandYoutube,
} from '@tabler/icons-react'
import { useIsOnMobile } from 'hooks/useIsOnMobile'
import { api, createBlogPostPath, createHeaders } from 'lib/cms'
import { BlogPostsQuery } from 'lib/cms/storyblok-generated/cms'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { useAuth } from 'providers/UserProvider'
import { ContactUsFormModal } from 'routes/LandingPage/ContactUsFormModal'

export const Footer = () => {
  const { userData } = useAuth()
  const isOnMobile = useIsOnMobile()
  const [data, setData] = useState<BlogPostsQuery | null>(null)
  const { isOpen, onClose, onOpen } = useDisclosure({})

  useEffect(() => {
    const fetchBlogPosts = async () => {
      const blogPosts = await api.BlogPosts(
        {
          postItemsSortBy: 'first_published_at:desc',
          per_page: 6,
          page: 1,
        },
        createHeaders({ shouldLoadDraftVersion: false }),
      )
      setData(blogPosts)
    }
    void fetchBlogPosts()
  }, [])

  return (
    <>
      <Box w="full" bg="#0D1267">
        <Container maxW="container.lg" centerContent>
          <Flex w="full" pb="24px">
            <Flex
              px="20px"
              py="12px"
              bgColor="white"
              borderBottomRadius="8px"
              border="1px #cccccc solid"
              borderTop="none"
            >
              <Text fontFamily="DM Mono Medium" fontSize="20px" lineHeight="150%">
                zadajdopyt.sk
              </Text>
            </Flex>
          </Flex>
          <VStack w="full" spacing="24px" pb="32px">
            <Stack w="full" direction="row" spacing="64px" pb="24px">
              <VStack w="full " align="start" spacing="32px">
                <Text fontFamily="DM Sans Semibold" fontSize="24px" lineHeight="150%" color="white">
                  Férové služby pre malé aj
                  <br />
                  veľké projekty!
                </Text>
                <Stack w="full" direction={{ base: 'column-reverse', md: 'row' }} spacing="16px">
                  <VStack w="full" align="start" spacing="16px">
                    {!isOnMobile && (
                      <>
                        <Link
                          href={Route.FrontPage()}
                          style={{
                            fontFamily: 'DM Sans Medium',
                            fontSize: '20px',
                            lineHeight: '150%',
                            color: 'white',
                          }}
                        >
                          Zakazník
                        </Link>
                        <Link
                          href={Route.Public.ContractorLanding()}
                          style={{
                            fontFamily: 'DM Sans Medium',
                            fontSize: '20px',
                            lineHeight: '150%',
                            color: 'white',
                          }}
                        >
                          Dodávateľ
                        </Link>
                      </>
                    )}
                    <Link
                      href={userData ? Route.App.Support() : Route.Public.Faq()}
                      style={{
                        fontFamily: 'DM Sans Medium',
                        fontSize: '20px',
                        lineHeight: '150%',
                        color: 'white',
                      }}
                    >
                      Podpora
                    </Link>
                    <VStack align="start" spacing={0} color="brand.darkBlue40">
                      <Link
                        href={Route.Public.TermsAndConditions()}
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Podmienky používania
                      </Link>
                      <a
                        href="https://zadajdopyt.sk/support/ochrana-sukromia"
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Ochrana súkromia
                      </a>
                      <Link
                        href={userData ? Route.App.Support() : Route.Public.Faq()}
                        onClick={() => {
                          onOpen()
                        }}
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Kontaktujte nás
                      </Link>
                      <Link
                        href={userData ? Route.App.Support() : Route.Public.Faq()}
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Často kladené otázky
                      </Link>
                    </VStack>
                  </VStack>

                  <VStack w="full" align="start" spacing="16px">
                    {isOnMobile && (
                      <>
                        <Link
                          href={Route.FrontPage()}
                          style={{
                            fontFamily: 'DM Sans Medium',
                            fontSize: '20px',
                            lineHeight: '150%',
                            color: 'white',
                          }}
                        >
                          Zakazník
                        </Link>
                        <Link
                          href={Route.Public.ContractorLanding()}
                          style={{
                            fontFamily: 'DM Sans Medium',
                            fontSize: '20px',
                            lineHeight: '150%',
                            color: 'white',
                          }}
                        >
                          Dodávateľ
                        </Link>
                      </>
                    )}
                    <Link
                      href={userData ? Route.App.DemandList() : Route.Public.DemandList()}
                      prefetch={false}
                      style={{
                        fontFamily: 'DM Sans Medium',
                        fontSize: '20px',
                        lineHeight: '150%',
                        color: 'white',
                      }}
                    >
                      Prehľad dopytov
                    </Link>
                    <VStack align="start" spacing={0} color="brand.darkBlue40">
                      <Link
                        href={
                          userData
                            ? Route.App.DemandList({ listingTypeId: 1 })
                            : Route.Public.DemandList({ listingTypeId: 1 })
                        }
                        prefetch={false}
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Súkromné
                      </Link>
                      <Link
                        href={
                          userData
                            ? Route.App.DemandList({ listingTypeId: 2 })
                            : Route.Public.DemandList({ listingTypeId: 2 })
                        }
                        prefetch={false}
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Firemné
                      </Link>
                      <Link
                        href={
                          userData
                            ? Route.App.DemandList({ listingTypeId: 3 })
                            : Route.Public.DemandList({ listingTypeId: 3 })
                        }
                        prefetch={false}
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Pre bytové domy
                      </Link>
                      <Link
                        href={
                          userData
                            ? Route.App.DemandList({ listingTypeId: 4 })
                            : Route.Public.DemandList({ listingTypeId: 4 })
                        }
                        prefetch={false}
                        style={{
                          fontSize: '16px',
                          lineHeight: '150%',
                          color: 'inherit',
                        }}
                      >
                        Havarijné službyy
                      </Link>
                    </VStack>
                    <Link
                      href={userData ? Route.App.DirectOffers() : Route.Public.DirectOffers()}
                      prefetch={false}
                      style={{
                        fontFamily: 'DM Sans Medium',
                        fontSize: '20px',
                        lineHeight: '150%',
                        color: 'white',
                      }}
                    >
                      Priama ponuka
                    </Link>
                    <Link
                      href={Route.Blog()}
                      style={{
                        fontFamily: 'DM Sans Medium',
                        fontSize: '20px',
                        lineHeight: '150%',
                        color: 'white',
                      }}
                    >
                      Rady & Tipy
                    </Link>
                  </VStack>
                </Stack>

                <Text
                  style={{
                    fontFamily: 'DM Sans Medium',
                    fontSize: '20px',
                    lineHeight: '150%',
                    color: 'white',
                  }}
                >
                  Sledujte nás
                </Text>
                <HStack spacing="16px">
                  <Link target="_blank" href="https://www.facebook.com/Zadajdopyt">
                    <IconBrandFacebook stroke={1.5} color="white" />
                  </Link>
                  <Link target="_blank" href="https://www.instagram.com/zadajdopyt/">
                    <IconBrandInstagram stroke={1.5} color="white" />
                  </Link>
                  <Link target="_blank" href="https://www.youtube.com/@zadajdopyt">
                    <IconBrandYoutube stroke={1.5} color="white" />
                  </Link>
                  <Link target="_blank" href="https://www.tiktok.com/@zadajdopyt">
                    <IconBrandTiktok stroke={1.5} color="white" />
                  </Link>
                  <Link target="_blank" href="https://sk.pinterest.com/zadajdopyt/">
                    <IconBrandPinterest stroke={1.5} color="white" />
                  </Link>
                  {/* <IconBrandLinkedin stroke={1.5} color="white" /> */}
                </HStack>
              </VStack>
              {!isOnMobile && (
                <VStack w="full " align="start" spacing="32px">
                  <Text
                    fontFamily="DM Sans Semibold"
                    fontSize="24px"
                    lineHeight="150%"
                    color="white"
                  >
                    Rady & Tipy
                  </Text>
                  {data && data.PostItems?.items && (
                    <VStack
                      w="full"
                      align="start"
                      spacing="8px"
                      divider={<Divider borderColor="#CCCCCC" />}
                    >
                      {data.PostItems.items.map(
                        (item) =>
                          item && (
                            <Link
                              key={item.slug}
                              href={createBlogPostPath(item.slug!)}
                              style={{ minWidth: '100%' }}
                            >
                              <Flex w="full" justify="space-between">
                                <Text fontFamily="DM Sans Medium" fontSize="16px" color="white">
                                  {item.content?.title}
                                </Text>
                                <IconArrowRight stroke={1.5} color="white" />
                              </Flex>
                            </Link>
                          ),
                      )}
                    </VStack>
                  )}
                </VStack>
              )}
            </Stack>
            <Divider bg="white" />
            <Text fontSize="13px" color="white">
              © Copyright 2025 | Inblok s.r.o. | Tel.: +421 944 450 010{' '}
            </Text>
          </VStack>
        </Container>
      </Box>

      <ContactUsFormModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
