/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'

export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  BlockScalar: { input: any; output: any }
  ISO8601DateTime: { input: any; output: any }
  JsonScalar: { input: any; output: any }
}

export type Alternate = {
  __typename?: 'Alternate'
  fullSlug: Scalars['String']['output']
  id: Scalars['Int']['output']
  isFolder?: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  parentId?: Maybe<Scalars['Int']['output']>
  published: Scalars['Boolean']['output']
  slug: Scalars['String']['output']
}

export type Asset = {
  __typename?: 'Asset'
  alt?: Maybe<Scalars['String']['output']>
  copyright?: Maybe<Scalars['String']['output']>
  filename: Scalars['String']['output']
  focus?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type AuthorComponent = {
  __typename?: 'AuthorComponent'
  _editable?: Maybe<Scalars['String']['output']>
  _uid?: Maybe<Scalars['String']['output']>
  component?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type AuthorFilterQuery = {
  name?: InputMaybe<FilterQueryOperations>
  url?: InputMaybe<FilterQueryOperations>
}

export type AuthorItem = {
  __typename?: 'AuthorItem'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<AuthorComponent>
  created_at?: Maybe<Scalars['String']['output']>
  default_full_slug?: Maybe<Scalars['String']['output']>
  first_published_at?: Maybe<Scalars['String']['output']>
  full_slug?: Maybe<Scalars['String']['output']>
  group_id?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  is_startpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  meta_data?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parent_id?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published_at?: Maybe<Scalars['String']['output']>
  release_id?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sort_by_date?: Maybe<Scalars['String']['output']>
  tag_list?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type AuthorItems = {
  __typename?: 'AuthorItems'
  items?: Maybe<Array<Maybe<AuthorItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type BlogComponent = {
  __typename?: 'BlogComponent'
  _editable?: Maybe<Scalars['String']['output']>
  _uid?: Maybe<Scalars['String']['output']>
  component?: Maybe<Scalars['String']['output']>
  headline?: Maybe<Story>
  mostPopular?: Maybe<Array<Maybe<Story>>>
}

export type BlogComponentHeadlineArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type BlogComponentMostPopularArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
}

export type BlogFilterQuery = {
  headline?: InputMaybe<FilterQueryOperations>
  mostPopular?: InputMaybe<FilterQueryOperations>
}

export type BlogItem = {
  __typename?: 'BlogItem'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<BlogComponent>
  created_at?: Maybe<Scalars['String']['output']>
  default_full_slug?: Maybe<Scalars['String']['output']>
  first_published_at?: Maybe<Scalars['String']['output']>
  full_slug?: Maybe<Scalars['String']['output']>
  group_id?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  is_startpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  meta_data?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parent_id?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published_at?: Maybe<Scalars['String']['output']>
  release_id?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sort_by_date?: Maybe<Scalars['String']['output']>
  tag_list?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type BlogItems = {
  __typename?: 'BlogItems'
  items?: Maybe<Array<Maybe<BlogItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type CategoryComponent = {
  __typename?: 'CategoryComponent'
  _editable?: Maybe<Scalars['String']['output']>
  _uid?: Maybe<Scalars['String']['output']>
  component?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type CategoryFilterQuery = {
  name?: InputMaybe<FilterQueryOperations>
}

export type CategoryItem = {
  __typename?: 'CategoryItem'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<CategoryComponent>
  created_at?: Maybe<Scalars['String']['output']>
  default_full_slug?: Maybe<Scalars['String']['output']>
  first_published_at?: Maybe<Scalars['String']['output']>
  full_slug?: Maybe<Scalars['String']['output']>
  group_id?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  is_startpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  meta_data?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parent_id?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published_at?: Maybe<Scalars['String']['output']>
  release_id?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sort_by_date?: Maybe<Scalars['String']['output']>
  tag_list?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type CategoryItems = {
  __typename?: 'CategoryItems'
  items?: Maybe<Array<Maybe<CategoryItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type ContentItem = {
  __typename?: 'ContentItem'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<Scalars['JsonScalar']['output']>
  content_string?: Maybe<Scalars['String']['output']>
  created_at?: Maybe<Scalars['String']['output']>
  default_full_slug?: Maybe<Scalars['String']['output']>
  first_published_at?: Maybe<Scalars['String']['output']>
  full_slug?: Maybe<Scalars['String']['output']>
  group_id?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  is_startpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  meta_data?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parent_id?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published_at?: Maybe<Scalars['String']['output']>
  release_id?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sort_by_date?: Maybe<Scalars['String']['output']>
  tag_list?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type ContentItems = {
  __typename?: 'ContentItems'
  items?: Maybe<Array<Maybe<ContentItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type Datasource = {
  __typename?: 'Datasource'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type DatasourceEntries = {
  __typename?: 'DatasourceEntries'
  items: Array<DatasourceEntry>
  total: Scalars['Int']['output']
}

export type DatasourceEntry = {
  __typename?: 'DatasourceEntry'
  dimensionValue?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type Datasources = {
  __typename?: 'Datasources'
  items: Array<Datasource>
}

export type FilterQueryOperations = {
  /** Must match all values of given array */
  all_in_array?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Matches any value of given array */
  any_in_array?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Greater than date (Exmples: 2019-03-03 or 2020-03-03T03:03:03) */
  gt_date?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Greater than float value */
  gt_float?: InputMaybe<Scalars['Float']['input']>
  /** Greater than integer value */
  gt_int?: InputMaybe<Scalars['Int']['input']>
  /** Matches exactly one value */
  in?: InputMaybe<Scalars['String']['input']>
  /** Matches any value of given array */
  in_array?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Matches exactly one integer value */
  in_int?: InputMaybe<Scalars['Int']['input']>
  /** Matches exactly one value with a wildcard search using * */
  like?: InputMaybe<Scalars['String']['input']>
  /** Less than date (Format: 2019-03-03 or 2020-03-03T03:03:03) */
  lt_date?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Less than float value */
  lt_float?: InputMaybe<Scalars['Float']['input']>
  /** Less than integer value */
  lt_int?: InputMaybe<Scalars['Int']['input']>
  /** Matches all without the given value */
  not_in?: InputMaybe<Scalars['String']['input']>
  /** Matches all without the given value */
  not_like?: InputMaybe<Scalars['String']['input']>
}

export type LinkEntries = {
  __typename?: 'LinkEntries'
  items: Array<LinkEntry>
}

export type LinkEntry = {
  __typename?: 'LinkEntry'
  id?: Maybe<Scalars['Int']['output']>
  isFolder?: Maybe<Scalars['Boolean']['output']>
  isStartpage?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  parentId?: Maybe<Scalars['Int']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published?: Maybe<Scalars['Boolean']['output']>
  slug?: Maybe<Scalars['String']['output']>
  uuid?: Maybe<Scalars['String']['output']>
}

export type PageComponent = {
  __typename?: 'PageComponent'
  _editable?: Maybe<Scalars['String']['output']>
  _uid?: Maybe<Scalars['String']['output']>
  body?: Maybe<Scalars['BlockScalar']['output']>
  component?: Maybe<Scalars['String']['output']>
}

export type PageItem = {
  __typename?: 'PageItem'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<PageComponent>
  created_at?: Maybe<Scalars['String']['output']>
  default_full_slug?: Maybe<Scalars['String']['output']>
  first_published_at?: Maybe<Scalars['String']['output']>
  full_slug?: Maybe<Scalars['String']['output']>
  group_id?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  is_startpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  meta_data?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parent_id?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published_at?: Maybe<Scalars['String']['output']>
  release_id?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sort_by_date?: Maybe<Scalars['String']['output']>
  tag_list?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type PageItems = {
  __typename?: 'PageItems'
  items?: Maybe<Array<Maybe<PageItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type PostComponent = {
  __typename?: 'PostComponent'
  _editable?: Maybe<Scalars['String']['output']>
  _uid?: Maybe<Scalars['String']['output']>
  categories?: Maybe<Array<Maybe<Story>>>
  component?: Maybe<Scalars['String']['output']>
  image?: Maybe<Asset>
  intro?: Maybe<Scalars['String']['output']>
  long_text?: Maybe<Scalars['JsonScalar']['output']>
  seoDescription?: Maybe<Scalars['String']['output']>
  seoKeywords?: Maybe<Scalars['String']['output']>
  seoTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type PostComponentCategoriesArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  language?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
}

export type PostFilterQuery = {
  categories?: InputMaybe<FilterQueryOperations>
  seoKeywords?: InputMaybe<FilterQueryOperations>
  seoTitle?: InputMaybe<FilterQueryOperations>
  title?: InputMaybe<FilterQueryOperations>
}

export type PostItem = {
  __typename?: 'PostItem'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<PostComponent>
  created_at?: Maybe<Scalars['String']['output']>
  default_full_slug?: Maybe<Scalars['String']['output']>
  first_published_at?: Maybe<Scalars['String']['output']>
  full_slug?: Maybe<Scalars['String']['output']>
  group_id?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  is_startpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  meta_data?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parent_id?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published_at?: Maybe<Scalars['String']['output']>
  release_id?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sort_by_date?: Maybe<Scalars['String']['output']>
  tag_list?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type PostItems = {
  __typename?: 'PostItems'
  items?: Maybe<Array<Maybe<PostItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type QueryType = {
  __typename?: 'QueryType'
  AuthorItem?: Maybe<AuthorItem>
  AuthorItems?: Maybe<AuthorItems>
  BlogItem?: Maybe<BlogItem>
  BlogItems?: Maybe<BlogItems>
  CategoryItem?: Maybe<CategoryItem>
  CategoryItems?: Maybe<CategoryItems>
  ContentNode?: Maybe<ContentItem>
  ContentNodes?: Maybe<ContentItems>
  DatasourceEntries?: Maybe<DatasourceEntries>
  Datasources?: Maybe<Datasources>
  Links?: Maybe<LinkEntries>
  PageItem?: Maybe<PageItem>
  PageItems?: Maybe<PageItems>
  PostItem?: Maybe<PostItem>
  PostItems?: Maybe<PostItems>
  RateLimit?: Maybe<RateLimit>
  SimplepostItem?: Maybe<SimplepostItem>
  SimplepostItems?: Maybe<SimplepostItems>
  Space?: Maybe<Space>
  Tags?: Maybe<Tags>
}

export type QueryTypeAuthorItemArgs = {
  find_by?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeAuthorItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']['input']>
  by_uuids?: InputMaybe<Scalars['String']['input']>
  by_uuids_ordered?: InputMaybe<Scalars['String']['input']>
  excluding_fields?: InputMaybe<Scalars['String']['input']>
  excluding_ids?: InputMaybe<Scalars['String']['input']>
  excluding_slugs?: InputMaybe<Scalars['String']['input']>
  fallback_lang?: InputMaybe<Scalars['String']['input']>
  filter_query?: InputMaybe<Scalars['JsonScalar']['input']>
  filter_query_v2?: InputMaybe<AuthorFilterQuery>
  first_published_at_gt?: InputMaybe<Scalars['String']['input']>
  first_published_at_lt?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['String']['input']>
  is_startpage?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  published_at_gt?: InputMaybe<Scalars['String']['input']>
  published_at_lt?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
  search_term?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
  with_tag?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeBlogItemArgs = {
  find_by?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeBlogItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']['input']>
  by_uuids?: InputMaybe<Scalars['String']['input']>
  by_uuids_ordered?: InputMaybe<Scalars['String']['input']>
  excluding_fields?: InputMaybe<Scalars['String']['input']>
  excluding_ids?: InputMaybe<Scalars['String']['input']>
  excluding_slugs?: InputMaybe<Scalars['String']['input']>
  fallback_lang?: InputMaybe<Scalars['String']['input']>
  filter_query?: InputMaybe<Scalars['JsonScalar']['input']>
  filter_query_v2?: InputMaybe<BlogFilterQuery>
  first_published_at_gt?: InputMaybe<Scalars['String']['input']>
  first_published_at_lt?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['String']['input']>
  is_startpage?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  published_at_gt?: InputMaybe<Scalars['String']['input']>
  published_at_lt?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
  search_term?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
  with_tag?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeCategoryItemArgs = {
  find_by?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeCategoryItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']['input']>
  by_uuids?: InputMaybe<Scalars['String']['input']>
  by_uuids_ordered?: InputMaybe<Scalars['String']['input']>
  excluding_fields?: InputMaybe<Scalars['String']['input']>
  excluding_ids?: InputMaybe<Scalars['String']['input']>
  excluding_slugs?: InputMaybe<Scalars['String']['input']>
  fallback_lang?: InputMaybe<Scalars['String']['input']>
  filter_query?: InputMaybe<Scalars['JsonScalar']['input']>
  filter_query_v2?: InputMaybe<CategoryFilterQuery>
  first_published_at_gt?: InputMaybe<Scalars['String']['input']>
  first_published_at_lt?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['String']['input']>
  is_startpage?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  published_at_gt?: InputMaybe<Scalars['String']['input']>
  published_at_lt?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
  search_term?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
  with_tag?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeContentNodeArgs = {
  find_by?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeContentNodesArgs = {
  by_slugs?: InputMaybe<Scalars['String']['input']>
  by_uuids?: InputMaybe<Scalars['String']['input']>
  by_uuids_ordered?: InputMaybe<Scalars['String']['input']>
  excluding_fields?: InputMaybe<Scalars['String']['input']>
  excluding_ids?: InputMaybe<Scalars['String']['input']>
  excluding_slugs?: InputMaybe<Scalars['String']['input']>
  fallback_lang?: InputMaybe<Scalars['String']['input']>
  filter_query?: InputMaybe<Scalars['JsonScalar']['input']>
  first_published_at_gt?: InputMaybe<Scalars['String']['input']>
  first_published_at_lt?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['String']['input']>
  is_startpage?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  published_at_gt?: InputMaybe<Scalars['String']['input']>
  published_at_lt?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
  search_term?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
  with_tag?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeDatasourceEntriesArgs = {
  datasource?: InputMaybe<Scalars['String']['input']>
  dimension?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
}

export type QueryTypeDatasourcesArgs = {
  by_ids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  search?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeLinksArgs = {
  paginated?: InputMaybe<Scalars['Boolean']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypePageItemArgs = {
  find_by?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypePageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']['input']>
  by_uuids?: InputMaybe<Scalars['String']['input']>
  by_uuids_ordered?: InputMaybe<Scalars['String']['input']>
  excluding_fields?: InputMaybe<Scalars['String']['input']>
  excluding_ids?: InputMaybe<Scalars['String']['input']>
  excluding_slugs?: InputMaybe<Scalars['String']['input']>
  fallback_lang?: InputMaybe<Scalars['String']['input']>
  filter_query?: InputMaybe<Scalars['JsonScalar']['input']>
  first_published_at_gt?: InputMaybe<Scalars['String']['input']>
  first_published_at_lt?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['String']['input']>
  is_startpage?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  published_at_gt?: InputMaybe<Scalars['String']['input']>
  published_at_lt?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
  search_term?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
  with_tag?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypePostItemArgs = {
  find_by?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypePostItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']['input']>
  by_uuids?: InputMaybe<Scalars['String']['input']>
  by_uuids_ordered?: InputMaybe<Scalars['String']['input']>
  excluding_fields?: InputMaybe<Scalars['String']['input']>
  excluding_ids?: InputMaybe<Scalars['String']['input']>
  excluding_slugs?: InputMaybe<Scalars['String']['input']>
  fallback_lang?: InputMaybe<Scalars['String']['input']>
  filter_query?: InputMaybe<Scalars['JsonScalar']['input']>
  filter_query_v2?: InputMaybe<PostFilterQuery>
  first_published_at_gt?: InputMaybe<Scalars['String']['input']>
  first_published_at_lt?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['String']['input']>
  is_startpage?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  published_at_gt?: InputMaybe<Scalars['String']['input']>
  published_at_lt?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
  search_term?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
  with_tag?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeSimplepostItemArgs = {
  find_by?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
  language?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeSimplepostItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']['input']>
  by_uuids?: InputMaybe<Scalars['String']['input']>
  by_uuids_ordered?: InputMaybe<Scalars['String']['input']>
  excluding_fields?: InputMaybe<Scalars['String']['input']>
  excluding_ids?: InputMaybe<Scalars['String']['input']>
  excluding_slugs?: InputMaybe<Scalars['String']['input']>
  fallback_lang?: InputMaybe<Scalars['String']['input']>
  filter_query?: InputMaybe<Scalars['JsonScalar']['input']>
  filter_query_v2?: InputMaybe<SimplepostFilterQuery>
  first_published_at_gt?: InputMaybe<Scalars['String']['input']>
  first_published_at_lt?: InputMaybe<Scalars['String']['input']>
  from_release?: InputMaybe<Scalars['String']['input']>
  is_startpage?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  published_at_gt?: InputMaybe<Scalars['String']['input']>
  published_at_lt?: InputMaybe<Scalars['String']['input']>
  resolve_links?: InputMaybe<Scalars['String']['input']>
  resolve_relations?: InputMaybe<Scalars['String']['input']>
  search_term?: InputMaybe<Scalars['String']['input']>
  sort_by?: InputMaybe<Scalars['String']['input']>
  starts_with?: InputMaybe<Scalars['String']['input']>
  with_tag?: InputMaybe<Scalars['String']['input']>
}

export type QueryTypeTagsArgs = {
  starts_with?: InputMaybe<Scalars['String']['input']>
}

export type RateLimit = {
  __typename?: 'RateLimit'
  maxCost: Scalars['Int']['output']
}

export type SimplepostComponent = {
  __typename?: 'SimplepostComponent'
  _editable?: Maybe<Scalars['String']['output']>
  _uid?: Maybe<Scalars['String']['output']>
  component?: Maybe<Scalars['String']['output']>
  long_text?: Maybe<Scalars['JsonScalar']['output']>
  seoDescription?: Maybe<Scalars['String']['output']>
  seoKeywords?: Maybe<Scalars['String']['output']>
  seoTitle?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SimplepostFilterQuery = {
  seoKeywords?: InputMaybe<FilterQueryOperations>
  seoTitle?: InputMaybe<FilterQueryOperations>
  title?: InputMaybe<FilterQueryOperations>
}

export type SimplepostItem = {
  __typename?: 'SimplepostItem'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<SimplepostComponent>
  created_at?: Maybe<Scalars['String']['output']>
  default_full_slug?: Maybe<Scalars['String']['output']>
  first_published_at?: Maybe<Scalars['String']['output']>
  full_slug?: Maybe<Scalars['String']['output']>
  group_id?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  is_startpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  meta_data?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parent_id?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  published_at?: Maybe<Scalars['String']['output']>
  release_id?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sort_by_date?: Maybe<Scalars['String']['output']>
  tag_list?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type SimplepostItems = {
  __typename?: 'SimplepostItems'
  items?: Maybe<Array<Maybe<SimplepostItem>>>
  total?: Maybe<Scalars['Int']['output']>
}

export type Space = {
  __typename?: 'Space'
  domain: Scalars['String']['output']
  id: Scalars['Int']['output']
  languageCodes: Array<Maybe<Scalars['String']['output']>>
  name: Scalars['String']['output']
  version: Scalars['Int']['output']
}

export type Story = {
  __typename?: 'Story'
  alternates?: Maybe<Array<Maybe<Alternate>>>
  content?: Maybe<Scalars['JsonScalar']['output']>
  createdAt?: Maybe<Scalars['String']['output']>
  firstPublishedAt?: Maybe<Scalars['String']['output']>
  fullSlug?: Maybe<Scalars['String']['output']>
  groupId?: Maybe<Scalars['Int']['output']>
  id?: Maybe<Scalars['Int']['output']>
  isStartpage?: Maybe<Scalars['Boolean']['output']>
  lang?: Maybe<Scalars['String']['output']>
  metaData?: Maybe<Scalars['JsonScalar']['output']>
  name?: Maybe<Scalars['String']['output']>
  parentId?: Maybe<Scalars['Int']['output']>
  path?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['Int']['output']>
  publishedAt?: Maybe<Scalars['String']['output']>
  releaseId?: Maybe<Scalars['Int']['output']>
  slug?: Maybe<Scalars['String']['output']>
  sortByDate?: Maybe<Scalars['String']['output']>
  tagList?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  translatedSlugs?: Maybe<Array<Maybe<TranslatedSlug>>>
  uuid?: Maybe<Scalars['String']['output']>
}

export type Tag = {
  __typename?: 'Tag'
  name: Scalars['String']['output']
  taggingsCount: Scalars['Int']['output']
}

export type Tags = {
  __typename?: 'Tags'
  items: Array<Tag>
}

export type TranslatedSlug = {
  __typename?: 'TranslatedSlug'
  lang: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  path?: Maybe<Scalars['String']['output']>
}

export const CategoryItemsFragmentDoc = gql`
  fragment categoryItems on CategoryItems {
    items {
      slug
      name
      uuid
    }
  }
`
export const PostItemsFragmentDoc = gql`
  fragment postItems on PostItems {
    items {
      slug
      published_at
      first_published_at
      content {
        long_text
        intro
        title
        seoTitle
        seoDescription
        seoKeywords
        image {
          alt
          filename
          title
        }
      }
    }
  }
`
export const CategoryDocument = gql`
  query Category($categoryItemId: ID!) {
    CategoryItem(id: $categoryItemId) {
      uuid
    }
  }
`
export const CategoriesDocument = gql`
  query Categories {
    CategoryItems {
      ...categoryItems
    }
  }
  ${CategoryItemsFragmentDoc}
`
export const BlogMetaDataDocument = gql`
  query BlogMetaData($blogItemId: ID!) {
    BlogItem(id: $blogItemId) {
      uuid
      content {
        headline(resolve_relations: "post.categories") {
          uuid
          slug
          firstPublishedAt
          name
          content
        }
        mostPopular {
          uuid
          slug
          content
        }
      }
    }
    CategoryItems {
      ...categoryItems
    }
  }
  ${CategoryItemsFragmentDoc}
`
export const PostBySlugDocument = gql`
  query PostBySlug($slug: ID!) {
    PostItem(id: $slug) {
      slug
      first_published_at
      content {
        _uid
        component
        long_text
        intro
        title
        seoTitle
        seoDescription
        seoKeywords
        image {
          alt
          filename
          title
        }
        categories {
          uuid
          name
          slug
        }
      }
    }
  }
`
export const SupportPageBySlugDocument = gql`
  query SupportPageBySlug($slug: ID!) {
    SimplepostItem(id: $slug) {
      slug
      first_published_at
      content {
        title
        long_text
      }
    }
  }
`
export const AllPostsWithSlugDocument = gql`
  query AllPostsWithSlug {
    PostItems {
      items {
        slug
      }
    }
  }
`
export const BlogPostsDocument = gql`
  query BlogPosts(
    $postItemsFilterQueryV2: PostFilterQuery
    $postItemsSortBy: String
    $per_page: Int
    $page: Int
  ) {
    PostItems(
      filter_query_v2: $postItemsFilterQueryV2
      sort_by: $postItemsSortBy
      per_page: $per_page
      page: $page
    ) {
      items {
        first_published_at
        slug
        content {
          image {
            alt
            filename
            title
          }
          intro
          title
          _uid
          seoTitle
          seoDescription
          seoKeywords
          categories {
            uuid
            name
            slug
          }
        }
      }
      total
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action()

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    Category(
      variables: CategoryQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CategoryQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CategoryQuery>(CategoryDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Category',
      )
    },
    Categories(
      variables?: CategoriesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<CategoriesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CategoriesQuery>(CategoriesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Categories',
      )
    },
    BlogMetaData(
      variables: BlogMetaDataQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<BlogMetaDataQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BlogMetaDataQuery>(BlogMetaDataDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'BlogMetaData',
      )
    },
    PostBySlug(
      variables: PostBySlugQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<PostBySlugQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostBySlugQuery>(PostBySlugDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'PostBySlug',
      )
    },
    SupportPageBySlug(
      variables: SupportPageBySlugQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<SupportPageBySlugQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SupportPageBySlugQuery>(SupportPageBySlugDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'SupportPageBySlug',
      )
    },
    AllPostsWithSlug(
      variables?: AllPostsWithSlugQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<AllPostsWithSlugQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AllPostsWithSlugQuery>(AllPostsWithSlugDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'AllPostsWithSlug',
      )
    },
    BlogPosts(
      variables?: BlogPostsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers'],
    ): Promise<BlogPostsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<BlogPostsQuery>(BlogPostsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'BlogPosts',
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
export type CategoryItemsFragment = {
  __typename?: 'CategoryItems'
  items?: Array<{
    __typename?: 'CategoryItem'
    slug?: string | null
    name?: string | null
    uuid?: string | null
  } | null> | null
}

export type PostItemsFragment = {
  __typename?: 'PostItems'
  items?: Array<{
    __typename?: 'PostItem'
    slug?: string | null
    published_at?: string | null
    first_published_at?: string | null
    content?: {
      __typename?: 'PostComponent'
      long_text?: any | null
      intro?: string | null
      title?: string | null
      seoTitle?: string | null
      seoDescription?: string | null
      seoKeywords?: string | null
      image?: {
        __typename?: 'Asset'
        alt?: string | null
        filename: string
        title?: string | null
      } | null
    } | null
  } | null> | null
}

export type CategoryQueryVariables = Exact<{
  categoryItemId: Scalars['ID']['input']
}>

export type CategoryQuery = {
  __typename?: 'QueryType'
  CategoryItem?: { __typename?: 'CategoryItem'; uuid?: string | null } | null
}

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>

export type CategoriesQuery = {
  __typename?: 'QueryType'
  CategoryItems?: {
    __typename?: 'CategoryItems'
    items?: Array<{
      __typename?: 'CategoryItem'
      slug?: string | null
      name?: string | null
      uuid?: string | null
    } | null> | null
  } | null
}

export type BlogMetaDataQueryVariables = Exact<{
  blogItemId: Scalars['ID']['input']
}>

export type BlogMetaDataQuery = {
  __typename?: 'QueryType'
  BlogItem?: {
    __typename?: 'BlogItem'
    uuid?: string | null
    content?: {
      __typename?: 'BlogComponent'
      headline?: {
        __typename?: 'Story'
        uuid?: string | null
        slug?: string | null
        firstPublishedAt?: string | null
        name?: string | null
        content?: any | null
      } | null
      mostPopular?: Array<{
        __typename?: 'Story'
        uuid?: string | null
        slug?: string | null
        content?: any | null
      } | null> | null
    } | null
  } | null
  CategoryItems?: {
    __typename?: 'CategoryItems'
    items?: Array<{
      __typename?: 'CategoryItem'
      slug?: string | null
      name?: string | null
      uuid?: string | null
    } | null> | null
  } | null
}

export type PostBySlugQueryVariables = Exact<{
  slug: Scalars['ID']['input']
}>

export type PostBySlugQuery = {
  __typename?: 'QueryType'
  PostItem?: {
    __typename?: 'PostItem'
    slug?: string | null
    first_published_at?: string | null
    content?: {
      __typename?: 'PostComponent'
      _uid?: string | null
      component?: string | null
      long_text?: any | null
      intro?: string | null
      title?: string | null
      seoTitle?: string | null
      seoDescription?: string | null
      seoKeywords?: string | null
      image?: {
        __typename?: 'Asset'
        alt?: string | null
        filename: string
        title?: string | null
      } | null
      categories?: Array<{
        __typename?: 'Story'
        uuid?: string | null
        name?: string | null
        slug?: string | null
      } | null> | null
    } | null
  } | null
}

export type SupportPageBySlugQueryVariables = Exact<{
  slug: Scalars['ID']['input']
}>

export type SupportPageBySlugQuery = {
  __typename?: 'QueryType'
  SimplepostItem?: {
    __typename?: 'SimplepostItem'
    slug?: string | null
    first_published_at?: string | null
    content?: {
      __typename?: 'SimplepostComponent'
      title?: string | null
      long_text?: any | null
    } | null
  } | null
}

export type AllPostsWithSlugQueryVariables = Exact<{ [key: string]: never }>

export type AllPostsWithSlugQuery = {
  __typename?: 'QueryType'
  PostItems?: {
    __typename?: 'PostItems'
    items?: Array<{ __typename?: 'PostItem'; slug?: string | null } | null> | null
  } | null
}

export type BlogPostsQueryVariables = Exact<{
  postItemsFilterQueryV2?: InputMaybe<PostFilterQuery>
  postItemsSortBy?: InputMaybe<Scalars['String']['input']>
  per_page?: InputMaybe<Scalars['Int']['input']>
  page?: InputMaybe<Scalars['Int']['input']>
}>

export type BlogPostsQuery = {
  __typename?: 'QueryType'
  PostItems?: {
    __typename?: 'PostItems'
    total?: number | null
    items?: Array<{
      __typename?: 'PostItem'
      first_published_at?: string | null
      slug?: string | null
      content?: {
        __typename?: 'PostComponent'
        intro?: string | null
        title?: string | null
        _uid?: string | null
        seoTitle?: string | null
        seoDescription?: string | null
        seoKeywords?: string | null
        image?: {
          __typename?: 'Asset'
          alt?: string | null
          filename: string
          title?: string | null
        } | null
        categories?: Array<{
          __typename?: 'Story'
          uuid?: string | null
          name?: string | null
          slug?: string | null
        } | null> | null
      } | null
    } | null> | null
  } | null
}
