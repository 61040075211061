import { ReactNode } from 'react'
import { Button } from '@chakra-ui/react'
import { IconHelp } from '@tabler/icons-react'
import { Tooltip } from './Tooltip'

interface ExplanatoryTooltipProps {
  label: string | ReactNode
  size?: number
}

export const ExplanatoryTooltip = ({ label, size = 20 }: ExplanatoryTooltipProps) => (
  <Tooltip content={label}>
    <Button variant="ghost" p={0} alignContent="center">
      <IconHelp size={size} stroke={1.5} />
    </Button>
  </Tooltip>
)
