import { GraphQLClient } from 'graphql-request'
import { getSdk } from './storyblok-generated/cms'

enum VERSION {
  Draft = 'draft',
  Published = 'published',
}

const Client = new GraphQLClient(`https://gapi.storyblok.com/v1/api`, {
  headers: {
    Token: String(process.env.NEXT_PUBLIC_STORYBLOK_API_KEY),
    Version: process.env.NODE_ENV === 'development' ? VERSION.Draft : VERSION.Published,
  },
})

// eslint-disable-next-line @typescript-eslint/naming-convention
export const api = getSdk(Client)

export const createHeaders = ({
  shouldLoadDraftVersion,
}: {
  shouldLoadDraftVersion?: boolean
}) => ({
  Version: shouldLoadDraftVersion ? VERSION.Draft : VERSION.Published,
})
