import { ReactNode } from 'react'
import {
  Box,
  Button,
  Flex,
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps as ChakraModalProps,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import { PropsWithChildrenRequired } from 'constants/typings'

interface ModalProps extends Omit<ChakraModalProps, 'children'> {
  onSuccess?: () => void
  isOpen: boolean
  onClose: () => void
  title?: string
  hasCloseIcon?: boolean
  icon?: ReactNode
  closeBtnText?: string
  submitBtnText?: string
  hasActionBar?: boolean
  width?: string
  isSubmitting?: boolean
}

export const Modal = ({
  onSuccess,
  isOpen,
  onClose,
  title,
  hasCloseIcon = false,
  icon,
  children,
  closeBtnText,
  submitBtnText,
  hasActionBar = false,
  width,
  isSubmitting,
  ...rest
}: PropsWithChildrenRequired<ModalProps>) => (
  <ChakraModal isOpen={isOpen} onClose={onClose} {...rest}>
    <ModalOverlay />
    <ModalContent maxWidth={width ?? '520px'} m="8px">
      <ModalBody p="24px">
        <VStack spacing="32px">
          {title && (
            <Flex w="full" justifyContent="space-between">
              {icon && icon}
              <Text w="full" fontFamily="DM Sans Bold" fontSize="24px" lineHeight="30px">
                {title}
              </Text>
              {hasCloseIcon && <IconX size="32px" onClick={onClose} />}
            </Flex>
          )}
          <Box w="full" h="full">
            {children}
          </Box>
          {hasActionBar && (
            <Flex w="full" justifyContent="end" gap="16px">
              <Button
                variant="outline"
                onClick={onClose}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                {closeBtnText ?? 'Zatvoriť'}
              </Button>
              <Button
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={() => {
                  onSuccess?.()
                  onClose()
                }}
              >
                {submitBtnText ?? 'Potvrdiť'}
              </Button>
            </Flex>
          )}
        </VStack>
      </ModalBody>
    </ModalContent>
  </ChakraModal>
)
