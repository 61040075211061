import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, ModalProps, Stack, Text, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconMailCheck, IconSend } from '@tabler/icons-react'
import { useSendContactUsEmailMutation } from 'generated/generated-graphql'
import { InferType } from 'yup'
import * as yup from 'yup'
import { Colors } from 'theme/constants'
import { FormSwitch, FormTextarea, FormTextInput } from 'components/inputs'
import { FormPhoneNumber } from 'components/inputs/PhoneInput/FormPhoneNumber'
import { Modal } from 'components/ui/Modal'

export enum FieldName {
  Name = 'name',
  Message = 'message',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  CallMe = 'callMe',
}

const YupContacUsFormValidationSchema = yup.object({
  [FieldName.Name]: yup.string().required('* Povinné'),
  [FieldName.Message]: yup.string().required('* Povinné'),
  [FieldName.Email]: yup
    .string()
    .email('Zadajte platnú e-mail adresu')
    .required('* Povinné')
    .typeError('Zadajte platnú e-mail adresu'),
  [FieldName.PhoneNumber]: yup.string(),
  [FieldName.CallMe]: yup.bool(),
})

export type YupContactUsFormValues = InferType<typeof YupContacUsFormValidationSchema>

export const ContactUsFormModal = ({ isOpen, onClose }: Omit<ModalProps, 'children'>) => {
  const [doShowSuccessMessage, setDoShowSuccessMessage] = useState(false)
  const [sendContactUsEmail] = useSendContactUsEmailMutation()
  const methods = useForm<YupContactUsFormValues>({
    resolver: yupResolver(YupContacUsFormValidationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })

  const {
    reset,
    watch,
    handleSubmit,
    formState: { errors: formErrors },
  } = methods

  const doCallMeWatch = watch(FieldName.CallMe)

  const submit = async ({ name, email, callMe, message, phoneNumber }: YupContactUsFormValues) => {
    await sendContactUsEmail({
      variables: {
        input: {
          area: 'Landing page',
          name,
          message,
          requesterEmail: email,
          phoneNumber: String(phoneNumber),
          callMe,
        },
      },
      onCompleted: () => {
        setDoShowSuccessMessage(true)
        reset()
      },
    })
  }

  return (
    <Modal
      hasCloseIcon
      title="Kontaktujte nás"
      isOpen={isOpen}
      onClose={() => {
        setDoShowSuccessMessage(false)
        reset()
        onClose()
      }}
    >
      <VStack w="full" spacing="48px">
        <Text color="brand.darkBlue80">
          Neváhajte a zavolajte nám na{' '}
          <span style={{ color: Colors.brand.blue100, fontWeight: 'bolder' }}>
            +421 944 450 010
          </span>{' '}
          alebo nám napíšte krátku správu.
        </Text>
        <FormProvider {...methods}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form style={{ width: '100%' }} onSubmit={handleSubmit(submit)}>
            <VStack
              w="full"
              align={doShowSuccessMessage ? 'center' : 'start'}
              spacing={doShowSuccessMessage ? '24px' : '68px'}
            >
              {doShowSuccessMessage ? (
                <>
                  <IconMailCheck stroke={1.5} size={120} color="#76CA66" />
                  <Text fontFamily="DM Sans SemiBold" fontSize="32px">
                    Ďakujeme
                  </Text>
                </>
              ) : (
                <>
                  <VStack w="full" align="start" spacing="24px">
                    <VStack w="full" align="start" spacing={0}>
                      <Stack w="full" direction={{ base: 'column', md: 'row' }} spacing="24px">
                        <FormTextInput
                          id={FieldName.Name}
                          label="Vaše meno"
                          placeholder="Zadajte vaše meno"
                          errorMessage={formErrors[FieldName.Name]?.message}
                          isRequired
                        />
                        <FormTextInput
                          id={FieldName.Email}
                          label="Váš email"
                          placeholder="Zadajte váš email"
                          errorMessage={formErrors[FieldName.Email]?.message}
                          isRequired
                        />
                      </Stack>
                      <FormTextarea
                        id={FieldName.Message}
                        label="Vaša správa"
                        placeholder="Sem napíšte vašu správu"
                        errorMessage={formErrors[FieldName.Message]?.message}
                        backgroundColor="white"
                        isRequired
                        minH="260px"
                      />
                      <VStack w="full" align="start" spacing="24px">
                        <FormSwitch id={FieldName.CallMe} label="Kontaktovať ma na tel. číslo" />
                        {doCallMeWatch && (
                          <FormPhoneNumber
                            id={FieldName.PhoneNumber}
                            label="Telefóne číslo"
                            placeholder="Zadajte telefóne číslo"
                            errorMessage={formErrors.phoneNumber?.message}
                            isRequired
                          />
                        )}
                      </VStack>
                    </VStack>
                  </VStack>
                  <Button w="full" type="submit" rightIcon={<IconSend stroke={1.5} />}>
                    Odoslať správu
                  </Button>
                </>
              )}
            </VStack>
          </form>
        </FormProvider>
      </VStack>
    </Modal>
  )
}
