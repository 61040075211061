import React, { ReactElement, ReactNode } from 'react'
import {
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightElement,
  LayoutProps,
  Stack,
  StackDirection,
  Text,
  VStack,
} from '@chakra-ui/react'
import { IconAsteriskSimple } from '@tabler/icons-react'
import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'

export interface TextInputProps extends InputProps {
  id: string
  label?: string
  subLabel?: string
  labelDirection?: StackDirection
  inputWidth?: LayoutProps['w']
  errorMessageSpacing?: LayoutProps['h']
  rightElement?: ReactElement
  errorMessage?: string
  leftAddon?: ReactElement
  variant?: string
  bottomElement?: ReactNode
  tooltip?: ReactNode
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      inputWidth = 'full',
      label,
      subLabel,
      labelDirection = 'column',
      rightElement,
      errorMessage = '',
      leftAddon,
      variant,
      bottomElement,
      isRequired,
      tooltip,
      errorMessageSpacing = '20px',
      ...rest
    },
    ref,
  ) => (
    <Stack
      justifyContent="space-between"
      direction={labelDirection}
      align="start"
      alignItems={labelDirection === 'row' ? 'center' : 'left'}
      spacing="0"
      w={inputWidth}
      style={{ columnGap: '30px' }}
    >
      {label && !subLabel && (
        <Flex w="full" justify="space-between">
          <FormLabel
            variant="outline"
            whiteSpace={{ base: 'balance', md: 'nowrap' }}
            display="flex"
          >
            {label} {isRequired && <IconAsteriskSimple size="12px" color="#BA0000" />}
          </FormLabel>
          {tooltip && <ExplanatoryTooltip label={tooltip} />}
        </Flex>
      )}
      {label && subLabel && (
        <VStack spacing={0} alignItems="start">
          <Flex w="full" justify="space-between">
            <Text fontFamily="DM Sans Medium" fontSize="14px" color="brand.primary">
              {label} {isRequired && <IconAsteriskSimple size="12px" color="#BA0000" />}
            </Text>
            {tooltip && <ExplanatoryTooltip label={tooltip} />}
          </Flex>
          <Text pl="4px" color="brand.wildBlueYonder" fontSize="13px">
            {subLabel}
          </Text>
        </VStack>
      )}
      <InputGroup justifyContent="flex-end">
        {leftAddon && (
          <InputLeftAddon
            h="full"
            {...(variant === 'categorySearch' && {
              borderColor: '#CCCCCC',
              _groupActive: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
              _groupHover: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
              _groupFocusWithin: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
              transition: 'none',
              role: 'group',
            })}
          >
            {leftAddon}
          </InputLeftAddon>
        )}
        <Input
          data-hj-allow
          id={id}
          name={id}
          ref={ref}
          variant={variant}
          isInvalid={!!errorMessage}
          {...rest}
          {...(variant === 'categorySearch' && {
            borderLeft: 'none',
            borderColor: '#CCCCCC',
            _groupActive: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
            _groupHover: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
            _groupFocusWithin: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
            transition: 'none',
            role: 'group',
          })}
        />
        {rightElement && (
          <InputRightElement
            h="full"
            w="fit-content"
            {...(variant === 'categorySearch' && {
              borderLeft: 'none',
              borderColor: '#CCCCCC',
              _groupActive: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
              _groupHover: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
              _groupFocusWithin: { borderColor: 'brand.blue100', bg: 'brand.blue10' },
              transition: 'none',
              role: 'group',
            })}
          >
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
      <VStack align="start" minH="20px" spacing={0}>
        <Flex mt="4px">
          {errorMessage && (
            <Text variant="error" maxH={errorMessageSpacing}>
              {errorMessage}
            </Text>
          )}
        </Flex>
        {bottomElement}
      </VStack>
    </Stack>
  ),
)

export default TextInput
