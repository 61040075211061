import { Route } from 'constants/common/routes'

export const getImageDimensions = (url: string) => ({
  width: Number(url.split('/')[5].split('x')[0]),
  height: Number(url.split('/')[5].split('x')[1]),
})

export const generatePlaceholderUrl = (src: string) => `${src}/m/10x0`

export const createBlogPostPath = (slug: string): string => `${Route.Blog()}/${slug}`
