import { useFormContext } from 'react-hook-form'
import { TextInputProps } from '../TextInput'
import { getPrefix, removePrefix } from './countries'
import { PhoneNumberInput } from './PhoneInput'

export const FormPhoneNumber = ({ id, errorMessage, ...rest }: TextInputProps) => {
  const { register, setValue, watch } = useFormContext()

  const phoneNumberWatch = watch(id)

  return (
    <PhoneNumberInput
      {...rest}
      {...register(id)}
      onChange={(value, prefix) => {
        setValue(id, prefix + value)
      }}
      defaultPrefix={phoneNumberWatch ? getPrefix(phoneNumberWatch as string)! : '+421'}
      value={phoneNumberWatch ? removePrefix(phoneNumberWatch as string) : ''}
      errorMessage={errorMessage}
    />
  )
}
