import React, { ReactNode } from 'react'
import {
  Flex,
  FormLabel,
  Text,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  VStack,
} from '@chakra-ui/react'
import { IconAsteriskSimple } from '@tabler/icons-react'
import { ExplanatoryTooltip } from 'components/common/ExplanatoryTooltip'

export interface TextareaProps extends ChakraTextareaProps {
  id: string
  label?: string
  tooltip?: ReactNode | string
  errorMessage?: string
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ id, label, errorMessage, tooltip, isRequired, ...rest }, ref) => (
    <VStack w="full" align="start" spacing="4px">
      {label && (
        <Flex w="full" justify="space-between">
          <FormLabel variant="outline" whiteSpace="nowrap" display="flex">
            {label} {isRequired && <IconAsteriskSimple size="12px" color="#BA0000" />}
          </FormLabel>
          {tooltip && <ExplanatoryTooltip label={tooltip} />}
        </Flex>
      )}
      <ChakraTextarea
        data-hj-allow
        ref={ref}
        id={id}
        name={id}
        resize="none"
        variant="outline"
        background="white"
        {...rest}
      />
      <Flex mt="4px" h="20px">
        {errorMessage && (
          <Text variant="error" maxH="20px">
            {errorMessage}
          </Text>
        )}
      </Flex>
    </VStack>
  ),
)

export default Textarea
