const CountryPrefixes = [
  {
    prefix: '+421',
    name: 'Slovensko',
    iso: 'SK',
  },
  {
    prefix: '+420',
    name: 'Česká republika',
    iso: 'CZ',
  },
  {
    prefix: '+36',
    name: 'Maďarsko',
    iso: 'HU',
  },
  {
    prefix: '+48',
    name: 'Poľsko',
    iso: 'PL',
  },
  {
    prefix: '+380',
    name: 'Ukrajina',
    iso: 'UA',
  },
  {
    prefix: '+43',
    name: 'Rakúsko',
    iso: 'AT',
  },
]

const Prefixes = ['+421', '+420', '+36', '+48', '+380', '+43']

const getCountryTelCode = (countryIso: string) =>
  (countryIso && CountryPrefixes?.find(({ iso }) => iso === countryIso)?.prefix) ?? '+421'

const getCountryIsoByPrefix = (prefix: string) =>
  (prefix && CountryPrefixes.find(({ prefix: countryPrefix }) => countryPrefix === prefix)?.iso) ??
  'SK'

const removePrefix = (value: string) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prefix of Prefixes) {
    if (value?.startsWith(prefix)) {
      return value.slice(prefix.length)
    }
  }
  // No matching prefix found, return original number
  return value
}
const getPrefix = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return null
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const prefix of Prefixes) {
    if (phoneNumber.startsWith(prefix)) {
      return prefix
    }
  }
  // No matching prefix found, return null
  return null
}

export {
  CountryPrefixes,
  getCountryIsoByPrefix,
  getCountryTelCode,
  getPrefix,
  Prefixes,
  removePrefix,
}
